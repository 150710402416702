





























































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import EventBus from '@/misc/EventBus';
import Store from '@/models/Store.model';
import Dealer from '@/models/Dealer.model';
import { DealerRoles } from '@/enum/DealerRoles.enum';

@Component({
  components: {
    EditDealerComponent: () => import(
      /* webpackChunkName: "EditDealerComponent" */
      '@/components/EditDealer.component.vue'
    ),
    EditStoreComponent: () => import(
      /* webpackChunkName: "EditStoreComponent" */
      '@/components/EditStore.component.vue'
    )
  }
})
export default class RegisterView extends mixins(BaseMixin) {
  private isLoading: boolean = false;

  private showRegisterForm: boolean = true;

  private termsAndConditionsAccepted: boolean = false;
  private termsAndConditionsURL: string = `${process.env.VUE_APP_API_URL}/files/terms-and-conditions.html`;

  private steps = [
    { title: 'REGISTER.REGISTER_DEALER', icon: 'fas fa-user', active: true, invalid: false },
    { title: 'REGISTER.REGISTER_STORE', icon: 'fas fa-store', active: false, invalid: false },
    { title: 'REGISTER.FINISH', icon: 'fas fa-clipboard-check', active: false, invalid: false },
  ];

  private dealerStep = this.steps[0]; 
  private storeStep = this.steps[1];
  private finishStep = this.steps[2];

  mounted() {
    // Add event listener that handles created store
    EventBus.$on('STORE_CREATED', this.onStoreCreated);
    // Add event listener that handles finished dealer and store registration:
    EventBus.$on('DEALER_REGISTRATION_FINISHED', this.dealerRegistrationFinished);
    EventBus.$on('STORE_REGISTRATION_FINISHED', this.storeRegistrationFinished);
    // Add event listener that handles duplicate values (is only revealed after executing `create` in backend):
    EventBus.$on('DUPLICATE_DEALER_EMAIL', this.onDuplicateDealerEmail);
    EventBus.$on('DUPLICATE_STORE_NAME', this.onDuplicateStoreName);
  }

  // Creates new dealer object as store admin
  get newDealer() {
    const dealer = new Dealer();
    dealer.role = DealerRoles.STORE_ADMIN;
    dealer.store = null;
    return dealer;
  }

  private storeRegistrationFinished() {
    this.isLoading = true;
    if (!this.storeStep.invalid) {
      this.storeStep.active = false;
      this.finishStep.active = true;
    } else { // duplicate store name was thrown (store was not created yet)
      this.register();
    }
    this.isLoading = false;
  }

  private dealerRegistrationFinished() {
    this.isLoading = true;
    if (!this.dealerStep.invalid) {
      this.dealerStep.active = false;
      this.storeStep.active = true;
    } else { // store is created, but duplicate dealer email was thrown (dealer was not created yet)
      // Create dealer:
      EventBus.$emit('SAVE_DEALER');
    }
    this.isLoading = false;
  }

  private register() {
    this.isLoading = true;
    // Create store:
    EventBus.$emit('SAVE_STORE');
    this.isLoading = false;
  }

  // Store and dealer were successfully created:
  private registrationFinished() {
    this.showRegisterForm = false;
  }

  public onStoreCreated(store: Store) {
    // Set store for dealer in EditDealerComponent:
    EventBus.$emit('SET_DEALER_STORE', store);
    // Wait for event to finish:
    this.$nextTick(() => {
      // Create dealer:
      EventBus.$emit('SAVE_DEALER');
    });    
  }

  public onDuplicateDealerEmail() {
    this.steps.forEach(step => step.active = false);
    this.dealerStep.active = true;
    this.dealerStep.invalid = true;
  }

  public onDuplicateStoreName() {
    this.steps.forEach(step => step.active = false);
    this.storeStep.active = true;
    this.storeStep.invalid = true;
  }
}
